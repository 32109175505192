import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import './nav-bar.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';

class Footer extends Component {

    //<!-- Footer -->
    render() {
        return <footer class="bg-black small text-center text-footer">
            <div class="container">
                Copyright &copy; Royal 2019
            </div>
        </footer>
    }
}

export default withRouter(Footer);