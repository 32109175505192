import axios from '../api/AxiosInstance'

export const getAllWorkouts = async () => {
    const url = '/workouts';
    return await axios
        .get(url)
        .then((response) => {
            if(response.data !== "") {
                console.log(response);
            }
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
}

export const getUserWorkouts = async (uid) => {
    const url = '/workouts/' + uid;

    return await axios
        .get(url)
        .then((response) => {
            if (response.data !== "") {
                console.log(response);
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export const createNewWorkouts = async (fileName) => {
    const url = '/workouts/loadWorkouts';
    const data = new FormData();
    data.append('fileName', new File([fileName], { type: 'text/csv' }));

    // , {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }

    // }
    return await axios
        .post(url, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
        
            })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
//------------------------------------------------------------------------------------------------------------------------
// export const getUserMeasurments = async (userid) => {
//     const url = '/measurments/' + userid;

//     return await axios
//         .get(url)
//         .then((response) => {
//             if (response.data !== "") {
//                 console.log(response);
//             }
//             return response.data;
//         })
//         .catch((error) => {
//             throw error;
//         });
// }

// export const createNewUser = async (body) => {
//     const url = '/customer';

//     return await axios
//         .post(url,body)
//         .then((response) => {
//             return response.data;
//         })
//         .catch((error) => {
//             throw error;
//         });
// };