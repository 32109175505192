import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
// import './nav-bar.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactSection.scss';
// import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';

class ContactSection extends Component {

    //<!-- Contact Section -->
    render() {
    return <Container id='Info' class="contact-section">
                <Row>
                    <h1>InfoSection</h1>
                    <p>Enseño a hombres y mujeres que buscan cómo terminar el ciclo de "dietas" de arranque y parada para finalmente transformar sus habitos y mentalidad con herramientas sostenibles y fáciles de aprender.</p>
                </Row>
            </Container>
    }
}

export default withRouter(ContactSection);