import axios from "axios";
import { getHostName } from "./api-config";
// import Cookies from "universal-cookie";

// const cookies = new Cookies();

let headers = {
    // 'Authorization': 'Bearer ' + cookies.get("token")
    //'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
}

const AxiosInstance = axios.create({
    baseURL: getHostName(),
    headers: headers
});

export default AxiosInstance;
