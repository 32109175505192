import React from "react";
import {Switch} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import {Router} from "react-router-dom";
import history from "./helpers/history";

import HomePage from "./content/HomePage/index";
import SignIn from "./content/LogInPage/SignIn";
import SignUp from "./content/LogInPage/SignUp";
import Dashboard from "./content/Dashboard/Dashboard";

// import InventorySummary from "./components/InventorySummary/InventorySummary";
// import OrderDetailsForm from './components/OrderDetailsForm/OrderDetailsForm';
// import PartRequestsView from "./components/PartRequestsView/PartRequestsView";
// import DashboardFrame from "./components/ProviderDashboard/DashboardFrame";
// import NoAvailable from "./components/ErrorScreens/NoAvailable";
// import TabsContainer from "./components/ProviderTabs/TabsContainer";
// import TabsContainerRequester from "./components/RequesterTabs/TabsContainerRequester";
// import PublicRoute from "./components/PublicRoute/PublicRoute";

function Routes(props){

  const {errorMessage,displayComponent} = props;

  return (
  <Router history={history}>
    <Switch>
      <PrivateRoute exact key="/" path="/" component={HomePage} displayComponent={displayComponent.home} />
      <PrivateRoute exact key="/home" path="/home" component={HomePage} displayComponent={displayComponent.home} />
      <PrivateRoute exact key="/SignIn" path="/SignIn" component={SignIn} displayComponent={displayComponent.signIn} />
      <PrivateRoute exact key="/SignUp" path="/SignUp" component={SignUp} displayComponent={displayComponent.signUp} />
      <PrivateRoute exact key="/Dashboard" path="/Dashboard" component={Dashboard} displayComponent={displayComponent.dashboard} />
      {/* <PrivateRoute exact key="/SignIn" path="/SignIn" component={Home} displayComponent={displayComponent.home} />
      <PrivateRoute exact key="/part-number" path="/part-number" component={InventorySummary} displayComponent={displayComponent.partRequest} />

      <PrivateRoute exact key="/part-request" path="/part-request" component={OrderDetailsForm} displayComponent={displayComponent.partRequest} />
      <PrivateRoute exact key="/my-requests" path="/my-requests" component={PartRequestsView} displayComponent={displayComponent.myRequests}  />
      <PrivateRoute exact key="/my-requests/order/:orderId/request/:requestId" path="/my-requests/order/:orderId/request/:requestId" component={TabsContainerRequester} displayComponent={displayComponent.myRequests} />      
      <PrivateRoute exact key="/my-orders" path="/my-orders" component={DashboardFrame} displayComponent={displayComponent.myOrders}  />
      <PrivateRoute exact key="/my-orders/order/:orderId/request/:requestId" path="/my-orders/order/:orderId/request/:requestId" component={TabsContainer} displayComponent={displayComponent.myOrders} />      
      <PublicRoute exact key="/no-available" path="/no-available" component={() => <NoAvailable errorMessage={errorMessage.errorMessage} />} /> */}
    </Switch>
  </Router> 
  );
}

export default Routes;
