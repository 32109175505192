import React, { useContext, useCallback, useState } from "react";
// import { Link, withRouter } from 'react-router-dom';
import accountCircle from '../img/icon/baseline_account_circle_black_48dp.png'
import {Form, Row, Col, Button, Container, Figure} from 'react-bootstrap';
import { Redirect, useHistory } from "react-router-dom";
import fire from '../config/Firebase';
import './NavBar.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from '../context/AuthContext';
import { createNewUser } from '../service/UserDetailsService'
// import history from '../helpers/history';
import {  Input
    //Container,
    // Collapse,
    // Navbar,
    // NavbarToggler,
    // NavbarBrand,
    // NavItem,
    // NavLink,
    // UncontrolledDropdown,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
    } from 'reactstrap';

// class NavBar extends Component {
function SignUpForm() {
    // const [isOpen, setIsOpen] = useState(false);
    const [IsDashboardReady, setIsDashboardReady] = useState(false);

    // const toggle = () => setIsOpen(!isOpen);
    // const [error, seterror] = useState("");
    let history = useHistory();

    const handleSignUp = useCallback(async event => {
        event.preventDefault();
        const { email, password, name_field_signup, lastName_field_signup} = event.target.elements;
        console.log(name_field_signup.value, lastName_field_signup.value);//email.value,
        try{
            const responsefire = await fire
            .auth()
            .createUserWithEmailAndPassword(email.value, password.value);
            let body = {
                uid : responsefire.user.uid,
                email : email.value,
                name : name_field_signup.value,
                lastName : lastName_field_signup.value  
            }
            const response = await createNewUser(body);
            if (response != ''){
                history.push("/Dashboard");
            }
            // console.log(responsefire.user.uid);
            console.log(response);
        } catch(error) {
            alert(error);
        }
    }, [history]);

    // const { currentUser } = useContext(AuthContext);

    // if (currentUser) {
    //     return <Redirect to="/" />;
    // }
    // const handleSignUp = useCallback(async event => {
    //     event.preventDefault();
    //     const { email, password } = event.target.elements;
    //     try {
    //       await app
    //         .auth()
    //         .createUserWithEmailAndPassword(email.value, password.value);
    //       history.push("/");
    //     } catch (error) {
    //       alert(error);
    //     }
    //   }, [history]);

        // await fire
        //     .auth()
        //     .createUserWithEmailAndPassword(usuario.value, clave.value)
        //     .then(result => {
        //         console.log(result);
        //         history.push("/");
        //     })
        //     .catch(error => {
        //         seterror(error.message);
        //     });
        // <form className="login-form" onSubmit={handleSignUp}></form>
    //------------------------ ESTO YA FUNCIONA ---------------------------------------------------------------------------------------
    // return (
    // <div id="signin_div" class="container main-div login-form">
    //     <form className="login-form" onSubmit={handleSignUp}>
    //     {/* <form id="signup-form" class="form-signin" role="form" action="/examples/actions/confirmation.php" method="post"> */}
    //         <div class="avatar">
    //             {/* <img src="img/user(1).png" alt="Avatar"> */}
    //         </div>
    //         <h2 class="text-center">Member Sign Up</h2>
    //         <div class="form-group">
    //             <Input class="form-control" placeholder="Name" required="" autofocus="" type="text" id="name_field_signup"></Input>
    //         </div>
    //         <div class="form-group">
    //             <input class="form-control" placeholder="Last Name" required="" autofocus="" type="text" id="lastName_field_signup"></input>
    //         </div>
    //         <div class="form-group">
    //             <input type="email" class="form-control" name="email" placeholder="Email address" required="required"
    //                 id="email_field"></input>
    //         </div>
    //         <div class="form-group">
    //             <input type="password" class="form-control" name="password" placeholder="Password" required="required"
    //                 id="password_field"></input>
    //         </div>
    //         <div class="form-group">
    //             <button type="submit" class="btn btn-primary btn-lg btn-block">Sign Up</button>
    //         </div>
    //     </form>
    //     <p class="text-center small">Already have an account? <a href="/SignIn">Sign in here!</a></p>
    // </div>);
    //----------------------------------------------------------------------------------------------------------------------------------------
    return(<Container>
      <h1></h1>
      <h2 class="text-center">Member Sign Up</h2>
      <Row className="justify-content-md-center">
        <Col xs lg="2">
          <Figure>
            <Figure.Image
                width={171}
                height={180}
                alt="171x180"
                // src="holder.js/171x180"
                src={accountCircle}
            />
          </Figure>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs lg="5">
          <Form onSubmit={handleSignUp}>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                Nombre
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="name" placeholder="Name" id="name_field_signup"/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="lastName_field_signup">
              <Form.Label column sm={2}>
                Apellidos
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="lastName" placeholder="Last Name" id="lastName_field_signup"/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="email_field">
              <Form.Label column sm={2}>
                Email
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="email" placeholder="Email" id="email"/>
              </Col>
            </Form.Group>
          
            <Form.Group as={Row} className="mb-3" controlId="password_field">
              <Form.Label column sm={2}>
                Password
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="password" placeholder="Password" id="password"/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
              <Col sm={{ span: 10, offset: 2 }}>
                <Form.Check label="Remember me" />
              </Col>
            </Form.Group>
          
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 2 }}>
                <Button type="submit">Sign up</Button>
              </Col>
            </Form.Group>
          </Form>
          <p class="text-center small">Already have an account? <a href="/SignIn">Sign in here!</a></p>
        </Col>
      </Row>
      </Container>);
       
};

export default SignUpForm;
// export default withRouter(SignUpForm);