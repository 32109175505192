import React, { Component } from 'react';
import {Image, Card, Row, Col, Button, Container, Figure} from 'react-bootstrap';
//import Stack from 'react-bootstrap/Stack'
import { withRouter } from 'react-router-dom';
import './HomeHeader.scss';
import primaryImg from '../img/salud_y_bienestar.jpg';
import logoImg from '../img/Rebe Aguirre.png';
import secondImg from '../img/pexels-jessica.jpg';
// import './nav-bar.scss'
// import 'bootstrap/dist/css/bootstrap.min.css';
// Container, Form, FormGroup, Input, Label 
import PropTypes from "prop-types";
import '../content/HomePage/home-page.scss';
import './NavBar.scss';
//import { Button} from 'reactstrap';

class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableButton:'true',
            enableButtonFlag:true,
            hrefState:'/',
            text:''
        }
        
    //     // const { currentUser } = props;
    //     super(props);

    //     //     currentUser1: currentUser
    //     // };
    //     // this.getUser = this.getUser.bind(this);
    }

    // this.props.getCurrentUser(this.props.currentUser);
    // getUser() {
        //  currentUser = this.props.currentUser;
    // }
    // if (currentUser) {
        
        // console.log(currentUser.email);
        // sessionStorage.setItem('fireUID', currentUser1.uid);
        // sessionStorage.setItem('firemail', currentUser1.email);
    // }
    render() {
        if(this.props.currentUser){
            if(this.state.enableButtonFlag){
                console.log(this.props.currentUser);
                this.setState({enableButton:''});
                this.setState({enableButtonFlag:false});
                this.setState({hrefState:'/Dashboard'});
                this.setState({text:'Dashboard'});
            }
        }else{
            if(this.state.enableButtonFlag){
                this.setState({enableButtonFlag:false});
                this.setState({hrefState:'#Info'});
                this.setState({text:'Info'});
            }
        }
        
        /*if(this.props.currentUser){
            if(this.state.enableButtonFlag){
                console.log(this.props.currentUser);
                this.setState({enableButton:''});
                this.setState({enableButtonFlag:false});
            }
        }*/
        //------------------------------------------ YA FUNCIONA ------------------------------------------------------------------------------------------------------------------
        //<!-- Header -->
        // return <header class="masthead">
        //     <div class="container d-flex h-100 align-items-center">
        //         <div class="container">
        //             <img src={primaryImg} class="img-logo-royal img-logo img-fluid mx-auto d-block" alt="Responsive image" width={500} height={500}></img>
        //             <div class="row text-center mx-auto">  
        //                 <div class="col-sm">
        //                     <Button href="/Dashboard"disabled={this.state.enableButton} id="button_EventNow" data-toggle="modal" data-target="#exampleModalCenter" type="button" class="btn btn-sm btn-block">Dashboard</Button>
        //                     <Button id="button_EventNow" data-toggle="modal" data-target="#exampleModalCenter" type="button" class="btn btn-sm btn-block">Solicitar nutriólogo ahora</Button>
        //                     <button id="button_ScheduleEvent" onclick="ScheduleEvent()" type="button" class="btn btn-sm btn-block">Quiero agendar una cita</button>
        //                     <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        //                         <div class="modal-dialog modal-dialog-centered" role="document">
        //                             <div class="modal-content">
        //                                 <div class="modal-header">
        //                                     <h5 class="modal-title" id="exampleModalLongTitle">¡Solicitar nutriólogo ahora!</h5>
        //                                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        //                                         <span aria-hidden="true">&times;</span>
        //                                     </button>
        //                                 </div>
        //                                 <div class="modal-body">
        //                                   Contacta con nosotros para Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        //                                 </div>
        //                                 <div class="modal-footer">
        //                                     <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        //                                     <button id="button_EventNow" type="button" onclick="whatsapp_btn()" class="btn btn-primary">¿Te urge fotógrafo? Pídelo YA</button>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </header>
        //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------

        return(
            <Container fluid='true'>
                <Row className="salmon-color" >
                    <Col>
                        <Image src={logoImg} className='margin-logo'>

                        </Image>
                        {/*<Figure class='margin-logo'>
                            <Figure.Image
                                width={500}
                                height={100}
                                //alt="171x180"
                                src={logoImg}
                            />
                            <Figure.Caption>
                                Nulla vitae elit libero, a pharetra augue mollis interdum.
                            </Figure.Caption> 
                        </Figure>*/}
                        <div class='margin-logo-btn'>
                            <Button size="lg" href={this.state.hrefState}
                                    id="button_EventNow" data-toggle="modal" 
                                    className='btnText'
                                    type="button">{this.state.text}</Button>
                        </div>
                    </Col>
                    <Col>
                        <Image src={primaryImg}
                            fluid='true'
                            >
                        </Image>
                    </Col>
                </Row>
            </Container>
        );
    }
}

// HomeHeader.propTypes = {
//     currentUser: PropTypes.string,
//     getCurrentUser: PropTypes.func 
// };

export default withRouter(HomeHeader);