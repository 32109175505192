//import React from 'react';
import React, { Component } from 'react';
import whatsappLogo from '../../img/icon/baseline_whatsapp_white_24dp.png';
import NavBar from '../../components/NavBar';
import LogInForm from '../../components/LogInForm';
import ContactSection from '../../components/ContactSection';
import Footer from '../../components/Footer';

class SignIn extends Component {
    state = {
        isLoading: false,
        groups: []
    };

/* async componentDidMount() {
    const response = await fetch('/priceLimits');
    const body = await response.json();
    this.setState({ groups: body, isLoading: false });
} */

render() {
    const { groups, isLoading } = this.state;

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="App">
            <header className="App-header">
                {/*<img src={logo} className="App-logo" alt="logo" />*/}
                <div className="App-intro">
                    <NavBar/>
                    <LogInForm/>
                    <ContactSection/>
                    <Footer/>
                </div>
                <a href="https://wa.me/523333709489?text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre."
                    class="float" target="_blank">
                    {/* <i class="fa fa-whatsapp my-float"></i> */}
                    <img src={whatsappLogo}></img>
                </a>
            </header>
        </div>
    );
  }
}

export default SignIn;
