const apiVersion = "/api/v1";
export const getHostName = () => {

  if (window.location.hostname === "localhost") {
    return "http://localhost:8080" + apiVersion;
    //return "https://customerservice.rebeaguirre.com" + apiVersion;
  } else if (window.location.hostname.includes("rebeaguirre")) {
    //return "http://customerservice-lb-331951386.us-east-2.elb.amazonaws.com:8079" + apiVersion;
    return "https://customerservice.rebeaguirre.com" + apiVersion;
  } else {
    //return "http://customerservice-lb-331951386.us-east-2.elb.amazonaws.com:8079" + apiVersion;
    return "https://customerservice.rebeaguirre.com" + apiVersion;
  }
  //return "http://192.168.59.100:8080" + apiVersion;//minikube
  //return "http://192.168.100.73:8080" + apiVersion;//mac ip
  //return "http://localhost:8080" + apiVersion;

  // if (window.location.hostname === "localhost") {
  //   return "http://192.168.1.67:8080" + apiVersion;
  //   //return "http://192.168.100.20:8080" + apiVersion;
  //   //return "http://localhost:8080" + apiVersion;
  // } else if (window.location.hostname.includes("dev")) {
  //   return "https://provider-service-dev.dal1a.ciocloud.nonprod.intranet.ibm.com" + apiVersion;
  // } else if (window.location.hostname.includes("test")) {
  //   return "https://provider-service-test.wdc1a.ciocloud.nonprod.intranet.ibm.com" + apiVersion;
  // }else if (window.location.hostname.includes("uat")) {
  //   return "https://provider-service-uat.wdc1a.ciocloud.nonprod.intranet.ibm.com" + apiVersion;
  // } else {
  //   return "http://192.168.1.67:8080" + apiVersion;
  // }
};
