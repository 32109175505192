import axios from '../api/AxiosInstance'


export const getAllUsersDetails = async () => {
    const url = '/customers';

    return await axios
        .get(url)
        .then((response) => {
            if (response.data !== "") {
                console.log(response);
            //   if (!allowedLocationCodes.includes(response.data.locationCode)) {
            //     response.data = "";
            //   }
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export const getUserDetails = async (uid) => {
    const url = '/customer/' + uid;

    return await axios
        .get(url)
        .then((response) => {
            if (response.data !== "") {
                console.log(response);
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export const createNewUser = async (body) => {
    const url = '/customer';

    return await axios
        .post(url,body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};