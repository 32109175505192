//import React from 'react';
/*import logo from './logo.svg'; */
import React, { useState } from 'react';
//import './A';
//import { Content } from 'carbon-components-react/lib/components/UIShell';
// import Header from './components/Scenes/Header';
import {Redirect,BrowserRouter as Router} from "react-router-dom";
// import HomePage from './content/HomePage';
// import Singnin from './content/LogInPage/SignIn';
import Routes from './Routes';
//import EditPrice from './components/EditPrice';
import 'bootstrap/dist/css/bootstrap.min.css';
//import MaxMinPrice from './components/MaxMinPrice';
import {AuthProvider} from "./context/AuthContext"

//import axios from 'axios';
function App() {
  
  // const [isLoading, setIsLoading] = useState(true);
  // const [serviceError, setServiceError] = useState({
  //   status: false,
  //   message: ''
  // });

  const [displayComponent, setDisplayComponent] = useState({
    home: true,
    signIn: true,
    signUp: true,
    dashboard: true,
    partRequest: true,
    myRequests: true,
    myOrders: true
  });

  // const displayLoading = () => {
  //   return (
  //     <div className="bx--grid">
  //       <div className="bx--row">
  //         <Loading
  //           active
  //           description="Active loading indicator"
  //           small={false}
  //           withOverlay={true}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  const displayMainComponent = () => {
    return (
      <AuthProvider>
        <div>
          <div>
            <div className="bx--grid m-width">{/* className="bx--grid m-width" */}
              <div className="bx--row"> {/* <div className="bx--row"> */}
                <Routes displayComponent={displayComponent} />
              </div>
            </div>
          </div>
        </div>
      </AuthProvider>
    )
  }

  const displayErrorScreen = () => {
    return (
      <div>
        <Router>
          <Redirect to="/no-available" />
          {/* <Routes path="/no-available" errorMessage={serviceError.message} displayComponent={displayComponent} /> */}
        </Router>
      </div>
    )
  }

  const reloadPage = () => {
    window.location.reload(false);
  }

  return (
    // !serviceError.status ? (
      displayMainComponent()
    // ) : displayErrorScreen()
  );

}
export default App;
//----------------------------------------------------------------------------------------------------------------
// class App extends Component {

  // render() {
    // return (
      // <>
      // <div>
        // <div>
          {/* <AppHeader />
          {displayExpressConsent()}
          <AppSideNav roles={roles} /> */}
        //   <div className="bx--grid m-width">
        //     <div className="bx--row">
        //       <Routes displayComponent={displayComponent} />
        //     </div>
        //   </div>
        // </div>
          {/* <BrowserRouter> */}
            {/* <Switch> */}
              {/* <Route exact path="/" component={HomePage} /> */}
              {/* <Route exact path="/singin" component={Singnin} /> */}
              {/* <Route path='/' component={EditPrice} /> */}
              {/* <Route path="/repos" component={RepoPage} /> */}
            {/* </Switch> */}
          {/* </BrowserRouter> */}
      // </div>
        {/* <Header /> */}
        {/* <Content> */}
          {/* <Switch> */}
         {/* <Route exact path="/" component={HomePage} /> */}

            {/* <Route path="/repos" component={RepoPage} /> */}
          {/* </Switch> */}
        {/* </Content> */}
//       </>
//     );
//   }
// }

// export default App;
