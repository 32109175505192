import React, { useState, useEffect } from 'react';
import {Card, Container, Row, Table, Alert} from 'react-bootstrap';
import * as UserM from '../service/MeasurmentsService';
// import Ratio from 'react-bootstrap/Ratio'

function ProgressProfile() {
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    // const [groups, setGroups] = useState();
    const [userDetails, setUserDetails] = useState(null);

    const PUID = sessionStorage.getItem('fireUID');
    
    console.log(userDetails);

    useEffect(() =>{
        async function fetchData(){
            try{
                const response = await UserM.getUserMeasurments(PUID);
                if(response != ''){
                    setUserDetails(response);
                }
            } catch (error) {
                setIsLoading(false);
                setIsErrorFree(false)
            }
            setIsLoading(false);
            console.log('USER DETAILS:    ' + userDetails);
        }

        fetchData();
    },[]);


    const renderView = () => {
        return(
            <Container fluid>
                <Row>
                    <Table responsive>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Peso</th>
                            <th>Espalda</th>
                            <th>Cintura</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({length: userDetails.length}).map((_, index) => (
                            <tr>
                                <td>{userDetails[index].timestamp.trim()}</td>
                                <td>{userDetails[index].weight}</td>
                                <td>{userDetails[index].back}</td>
                                <td>{userDetails[index].waist}</td>
                            </tr>
                        ))}
                        {/* <tr>
                            <td>Peso</td>
                            {Array.from({ length: 3 }).map((_, index) => (
                                <td key={index}>{index}</td>
                            ))}
                        </tr> */}
                    </tbody>
                    </Table>
                </Row>
                <Row>
                    {/* <div style={{ width: 660, height: 'auto' }}>
                        <Ratio aspectRatio="16x9"> */}
                            <iframe height="1000" src="https://datastudio.google.com/embed/reporting/1PP21AH9vGoLHzHFwtR0qolyuNrNy-T1S/page/n4lX"></iframe>
                        {/* </Ratio>
                    </div> */}
                </Row>
                {/* <Card style={{ width: '18rem' }}>
                <Card.Body>
                    <Card.Title>Peso</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                    <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of
                    the card's content.
                    </Card.Text>
                    <Card.Link href="#">Card Link</Card.Link>
                    <Card.Link href="#">Another Link</Card.Link>
                </Card.Body>
            </Card> */}
            </Container>
        );
    }

    const createMainInformation = () => {
        if (isErrorFree) {
            return userDetails !== null ? renderView() : null;
        } else {
            return (
            <Container>
                <Alert variant='danger'>
                    <Alert.Heading>Error! Our apologies</Alert.Heading>
                    <p>
                        Something went wrong, try again later.
                    </p>
                {userDetails !== null ? renderView() : null}
                </Alert>
            </Container>
            );
        }
    };
    return(
        <>
        {createMainInformation()}
        </>
    )
};

export default ProgressProfile;