//import React from 'react';
import React, { useContext, useState, useEffect } from 'react';
import {Form, Row, Col, Button, Container, Figure} from 'react-bootstrap';
import whatsappLogo from '../../img/icon/baseline_whatsapp_white_24dp.png';
import NavBar from '../../components/NavBar';
import HomeHeader from '../../components/HomeHeader';
import ContactSection from '../../components/ContactSection';
import InfoSection from '../../components/InfoSection';
import Footer from '../../components/Footer';
import { AuthContext } from '../../context/AuthContext';
import factory from "../../ethereum/factory";
import './home-page.scss'

function HomePage() {
   
    const [isLoading, setIsLoading] = useState(false);
    /* async componentDidMount() {
    const response = await fetch('/priceLimits');
    const body = await response.json();
    this.setState({ groups: body, isLoading: false });
} */
    const {currentUser} = useContext(AuthContext);

    //CREO QUE ESTO ES WEB3 para usar MetaMask!
    /*useEffect(() =>{
        async function getInitialProps(){
            const campaigns = await factory.methods.getDeployedCampaigns().call();
            console.log(campaigns);
            setIsLoading(false);
        }

        getInitialProps();
    },[]);*/

    if (currentUser) {
        //setIsLoading(false);
        sessionStorage.setItem('fireUID', currentUser.uid);
        sessionStorage.setItem('firemail', currentUser.email);
    }

    if (isLoading) {
        return <p>Loading...</p>;
    }
    
    return (
        <Container fluid='true'>
            <NavBar currentUser={currentUser}/>
            <HomeHeader currentUser={currentUser}/>
            <InfoSection id="Info"/>
            <ContactSection/>
            <Footer/>
            <a href="https://wa.me/523333709489?text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre."
                class="float" target="_blank">
                {/* <i class="fa fa-whatsapp my-float"></i> */}
                <img src={whatsappLogo}></img>
            </a>
        </Container>
        // <div className="App">
        //     <header className="App-header">
        //         {/*<img src={logo} className="App-logo" alt="logo" />*/}
        //         <div className="App-intro">
        //             <NavBar currentUser={currentUser}/>
        //             <HomeHeader currentUser={currentUser}/>
        //             <ContactSection/>
        //             <Footer/>
        //         </div>
                // <a href="https://wa.me/523333709489?text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre."
                //     class="float" target="_blank">
                //     {/* <i class="fa fa-whatsapp my-float"></i> */}
                //     <img src={whatsappLogo}></img>
                // </a>
        //     </header>
        // </div>
    );
  }


export default HomePage;
