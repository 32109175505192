import React, { useState, useEffect } from 'react';
import {Container, Row, Table, Alert, Button} from 'react-bootstrap';
import * as UserDiet from '../service/UserDietService';

function Diet() {
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    // const [groups, setGroups] = useState();
    const [getUserDiet, setUserDiet] = useState(null);

    const PUID = sessionStorage.getItem('fireUID');
    
    console.log(getUserDiet);
    console.log(PUID);

    useEffect(() =>{
        async function fetchData(){
            try{
                const response = await UserDiet.getUserDiet(PUID);
                if(response != ''){
                    setUserDiet(response);
                }
            } catch (error) {
                setIsLoading(false);
                setIsErrorFree(false)
            }
            setIsLoading(false);
            console.log('USER DIET:    ' + getUserDiet);
        }

        fetchData();
    },[]);

    const renderView = () => {
        return(
            <div>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>DIETA</th>
                            <th>Lunes</th>
                            <th>Martes</th>
                            <th>Miercoles</th>
                            <th>Jueves</th>
                            <th>Viernes</th>
                            <th>Sabado</th>
                            <th>Domingo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
                            <th>Desayuno</th>
                            {Array.from({ length: 7 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                        </tr>
                        <tr>
                            <th>Colación 1</th>
                            {Array.from({ length: 7 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                        </tr>
                        <tr>
                            <th>Comida</th>
                            {Array.from({ length: 7 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                        </tr>
                        <tr>
                            <th>Colación 2</th>
                            {Array.from({ length: 7 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                        </tr>
                        <tr>
                            <th>Cena</th>
                            {Array.from({ length: 7 }).map((_, index) => (
                                <td key={index}>Table cell {index}</td>
                            ))}
                        </tr> */}
                        {Array.from({length: getUserDiet.length}).map((_, index) => (
                                    <tr>
                                        <th>{getUserDiet[index].dish}</th>
                                        <td>{getUserDiet[index].monday}</td>
                                        <td>{getUserDiet[index].tuesday}</td>
                                        <td>{getUserDiet[index].wednesday}</td>
                                        <td>{getUserDiet[index].thursday}</td>
                                        <td>{getUserDiet[index].friday}</td>
                                        <td>{getUserDiet[index].saturday}</td>
                                        <td>{getUserDiet[index].sunday}</td>
                                    </tr>
                                ))}
                    </tbody>
                </Table>
                <Button>Dawnload Excel File</Button>
            </div>
        );
    }

    const createMainInformation = () => {
        if (isErrorFree) {
            return getUserDiet !== null ? renderView() : null;
        } else {
            return (
            <Container>
                <Alert variant='danger'>
                    <Alert.Heading>Error! Our apologies</Alert.Heading>
                    <p>
                        Something went wrong, try again later.
                    </p>
                {getUserDiet !== null ? renderView() : null}
                </Alert>
            </Container>
            );
        }
    };
    return(
        <>
        {createMainInformation()}
        </>
    )

};

export default Diet;