import React, { useEffect, useState} from 'react';
import {Container, Figure, Card, Row, Alert, Col} from'react-bootstrap';
import accountCircle from '../img/icon/baseline_account_circle_black_48dp.png'
// import * as UserInfo from '../service/UserDetailsService';
// import { AuthContext } from '../context/AuthContext';

function Profile(props) {
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [groups, setGroups] = useState();
    // const [userDetails, setUserDetails] = useState(null);
    // const { currentUser } = useContext(AuthContext);

    // const PName = sessionStorage.getItem('fireUID') 
    // const PEmail = 
    // const PLastName = 
    const PUID = sessionStorage.getItem('fireUID'); 
    // console.log(sessionStorage.getItem('fireUID'));
    // console.log(currentUser.email);
    console.log(PUID);
    console.log(props);
    console.log(props.userDetail.role);
    // const fireUID = currentUser.

    // useEffect(() =>{
    //     if(UserInfo != ''){
    //         setUserDetails(UserInfo);
    //     }

    // },[])
    // useEffect(() =>{
    //     // setIsLoading(true);
    //     //const fetchData = async() =>{};
    //     async function fetchData(){
    //         try{
    //             const response = await UserInfo.getUserDetails(PUID);//PriceInfo.getPriceLimits();
    //             if(response != ''){
    //                 setUserDetails(response);
    //             }
    //         } catch (error) {
    //             setIsLoading(false);
    //             setIsErrorFree(false)
    //         }
    //         //const body = await response.json();
    //         setIsLoading(false);
    //         // console.log('USER DETAILS:    ' + userDetails);
    //     }

    //     fetchData();
    // },[]);

    const renderView = () => {
        return(
        <Container>
            <Figure>
                <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    // src="holder.js/171x180"
                    src={accountCircle}
                />
                <Figure.Caption>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                </Figure.Caption>
            </Figure>
            <Card>
                <Card.Header as="h5">Mi Perfil</Card.Header>
                <Card.Body>
                    <Card.Title>Hola! {props.userDetail.name} {props.userDetail.lastName} </Card.Title>
                    <Card.Text>Info del user.
                        <Row>
                            <Col md="auto">email : {props.userDetail.email}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="auto">phone : {props.userDetail.phone}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="auto">age : {props.userDetail.age}
                            </Col>
                        </Row>
                    With supporting text below as a natural lead-in to additional content.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );}

    const createMainInformation = () => {
        if (isErrorFree) {
            return props.userDetail !== null ? renderView() : null;
        } else {
            return (
            <Container>
                <Alert variant='danger'>
                    <Alert.Heading>Error! Our apologies</Alert.Heading>
                    <p>
                        Something went wrong, try again later.
                    </p>
                {props.userDetail !== null ? renderView() : null}
                </Alert>
            </Container>
            );
        }
    };
    return(
        <>
        {createMainInformation()}
        </>
    )
}

export default Profile;