import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; // if you are using Firebase Authentication
import 'firebase/firestore'; // if you are using Firestore
// Import other Firebase services you need

//informacion de la aplicacion copiada de firebase
const fire = firebase.initializeApp({
    apiKey: "AIzaSyDGLFAaj_qsARxWc7-mqnUWLTihtyensoA",
    authDomain: "nutrition-app-41f6c.firebaseapp.com",
    projectId: "nutrition-app-41f6c",
    storageBucket: "nutrition-app-41f6c.appspot.com",
    messagingSenderId: "109286053822",
    appId: "1:109286053822:web:5ab753393845a63bfb0bef",
    measurementId: "G-FJXPN74LWF"
});

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*const firebaseConfig = {
    apiKey: "AIzaSyDGLFAaj_qsARxWc7-mqnUWLTihtyensoA",
    authDomain: "nutrition-app-41f6c.firebaseapp.com",
    projectId: "nutrition-app-41f6c",
    storageBucket: "nutrition-app-41f6c.appspot.com",
    messagingSenderId: "109286053822",
    appId: "1:109286053822:web:5ab753393845a63bfb0bef",
    measurementId: "G-FJXPN74LWF"
  };*/

// const fire = firebase.initializeApp(config);
export default fire