//import React from 'react';
import React, { Component } from 'react';
import whatsappLogo from '../../img/icon/baseline_whatsapp_white_24dp.png'
import {Container, Figure, Card, Row, Alert, Col} from'react-bootstrap';
import NavBar from '../../components/NavBar';
import ControlledTabs from '../../components/ControlledTabs';
import ContactSection from '../../components/ContactSection';
import Footer from '../../components/Footer';

class Dashboard extends Component {
    state = {
        isLoading: false,
        groups: []
    };

/* async componentDidMount() {
    const response = await fetch('/priceLimits');
    const body = await response.json();
    this.setState({ groups: body, isLoading: false });
} */

render() {
    const { groups, isLoading } = this.state;

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="App">
            <header className="App-header">
                {/*<img src={logo} className="App-logo" alt="logo" />*/}
                <div className="App-intro">
                    <NavBar currentUser='true'/>
                    <Container fluid>
                        <Row className='mb-5'><ControlledTabs/></Row>
                        
                        <Row><ContactSection/></Row>
                    </Container>
                    <Footer/>
                </div>
                <a href="https://wa.me/523333709489?text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre."
                    class="float" target="_blank">
                    {/* <i class="fa fa-whatsapp my-float"></i> */}
                    <img src={whatsappLogo}></img>
                </a>
            </header>
        </div>
    );
  }
}

export default Dashboard;
