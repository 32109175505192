import React, { useState, useEffect } from 'react';
import * as UserWorkouts from '../service/WorkoutsService';
import {Table, Container, Alert, Card} from 'react-bootstrap'

function WorkOut() {
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    // const [groups, setGroups] = useState();
    const [getUserWorkouts, setUserWorkouts] = useState(null);
    const [getColumnSize, setColumnSize] = useState(null);

    const weekDays = ['Lunes','Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
    const PUID = sessionStorage.getItem('fireUID');
    
    console.log(getUserWorkouts);
    console.log(PUID);
    console.log(getColumnSize);
    
    useEffect(() =>{
        async function fetchData(){
            try{
                //const response = await UserWorkouts.getUserWorkouts(PUID);
                const response = await UserWorkouts.getUserWorkouts(PUID);
                if(response != ''){
                    setUserWorkouts(response);
                    console.log(response);
                    setColumnSize(workoutLength(response));
                    console.log(getColumnSize);
                }
                // if (getUserWorkouts != null){
                //     setColumnSize(workoutLength(getUserWorkouts));
                //     console.log(getColumnSize);
                // }
            } catch (error) {
                setIsLoading(false);
                setIsErrorFree(false)
            }
            setIsLoading(false);
            if (getUserWorkouts != null){
                setColumnSize(workoutLength(getUserWorkouts));
                console.log(getColumnSize);
            }
            console.log('USER WORKOUTS:    ' + getUserWorkouts);
        }

        fetchData();
    },[]);

    const workoutLength = (getUserWorkouts) => {
        var list = [];
        for (var i = 0; i < getUserWorkouts.length; i++) {
            list[i] = getUserWorkouts[i].day;
        }
        // Create Dictionary to store word
        // and it's frequency
        var hs = {};
 
        // Iterate through array of words
        for (var i = 0; i < list.length; i++) {
          // If word already exist in Dictionary
          // then increase it's count by 1
          if (hs.hasOwnProperty(list[i])) {
            hs[list[i]] = hs[list[i]] + 1;
          }
 
          // Otherwise add word to Dictionary
          else {
            hs[list[i]] = 1;
          }
        }
 
        // Create set to iterate over Dictionary
        var Key = "";
        var Value = 0;
 
        for (const [key, value] of Object.entries(hs)) {
          // Check for word having highest frequency
          if (value > Value) {
            Value = value;
            Key = key;
          }
        }
        
        console.log(Value);
        // Return word having highest frequency
        return Value;

    };

    //(uid) => {
    const getWorkoutCardByWeekDay = (indexa, index, day) => {
        //console.log(day)
        //console.log(getUserWorkouts[index].day)
        if(getUserWorkouts[index].day == day && getUserWorkouts[index].exerNum == indexa){
            return(
                <Card >
                    <Card.Body>
                        <Card.Title>{getUserWorkouts[index].title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{getUserWorkouts[index].repsMethod}</Card.Subtitle>
                        <Card.Text>
                            {getUserWorkouts[index].description}
                        </Card.Text>
                        <Card.Link href="#">{day}</Card.Link>
                        <Card.Link href="#">{getUserWorkouts[index].day}</Card.Link>
                        <Card.Link href="#">{getUserWorkouts[index].exerNum}</Card.Link>
                        {/* <Card.Link href="#">Another Link</Card.Link> */}
                    </Card.Body>
                </Card>
            );
        }else{
            return ;
        }

    };

    const renderView = () => {
        return(
            // <Table striped bordered hover size="sm">
            // <Table striped="columns">
            // <Table responsive="sm">
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Lunes</th>
                        <th>Martes</th>
                        <th>Miércoles</th>
                        <th>Jueves</th>
                        <th>Viernes</th>
                        <th>Sabado</th>
                        <th>Domingo</th>
                    </tr>
                </thead>
                <tbody> 
                    {Array.from({length: getColumnSize}).map((_, index) => (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>  {/*Column Lunes*/}
                                        {Array.from({length: getUserWorkouts.length}).map((_, indexB) => (
                                            getWorkoutCardByWeekDay(index+1, indexB, weekDays[0])
                                        ))}
                                    </td>
                                    <td>  {/*Column Martes*/}
                                        {Array.from({length: getUserWorkouts.length}).map((_, indexB) => (
                                            getWorkoutCardByWeekDay(index+1, indexB, weekDays[1])
                                        ))}
                                    </td>
                                    <td>  {/*Column Miercoles*/}
                                        {Array.from({length: getUserWorkouts.length}).map((_, indexB) => (
                                            getWorkoutCardByWeekDay(index+1, indexB, weekDays[2])
                                        ))}
                                    </td>
                                    <td>  {/*Column Jueves*/}
                                        {Array.from({length: getUserWorkouts.length}).map((_, indexB) => (
                                            getWorkoutCardByWeekDay(index+1, indexB, weekDays[3])
                                        ))}
                                    </td>
                                    <td>  {/*Column Viernes*/}
                                        {Array.from({length: getUserWorkouts.length}).map((_, indexB) => (
                                            getWorkoutCardByWeekDay(index+1, indexB, weekDays[4])
                                        ))}
                                    </td>
                                    <td>  {/*Column Sabado*/}
                                        {Array.from({length: getUserWorkouts.length}).map((_, indexB) => (
                                            getWorkoutCardByWeekDay(index+1, indexB, weekDays[5])
                                        ))}
                                    </td>
                                    <td>  {/*Column Domingo*/}
                                        {Array.from({length: getUserWorkouts.length}).map((_, indexB) => (
                                            getWorkoutCardByWeekDay(index+1, indexB, weekDays[6])
                                        ))}
                                    </td>
                                    {/* <td>{getUserWorkouts[index].timestamp}</td> */}
                                    {/* <td>{getUserWorkouts[index].customerId}</td> */}
                                    {/* <td>
                                        {getWorkoutCardByWeekDay(index, 'Lunes')}
                                    </td>
                                    <td>
                                        HOLA
                                    </td>
                                    <td>{getWorkoutCardByWeekDay(index, 'Martes')}</td>
                                    <td>{getWorkoutCardByWeekDay(index, 'Miercoles')}</td>
                                    <td>{getWorkoutCardByWeekDay(index, 'Jueves')}</td>
                                    <td>{getWorkoutCardByWeekDay(index, 'Viernes')}</td>
                                    <td>{getWorkoutCardByWeekDay(index, 'Sabado')}</td>
                                    <td>{getWorkoutCardByWeekDay(index, 'Domingo')}</td> */}
                                </tr>
                            ))}
                </tbody>
            </Table>
        );
    }

    const createMainInformation = () => {
        if (isErrorFree) {
            return getUserWorkouts !== null ? renderView() : null;
        } else {
            return (
            <Container>
                <Alert variant='danger'>
                    <Alert.Heading>Error! Our apologies</Alert.Heading>
                    <p>
                        Something went wrong, try again later.
                    </p>
                {getUserWorkouts !== null ? renderView() : null}
                </Alert>
            </Container>
            );
        }
    };
    return(
        <>
        {createMainInformation()}
        </>
    )

    return(
        <div>
            <Table responsive="sm">
                <thead>
                <h1>fdfasfd</h1>
                <tr>
                    <th>#</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                </tbody>
            </Table>
            <Table responsive="md">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                </tbody>
            </Table>
            <Table responsive="lg">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                </tbody>
            </Table>
            <Table responsive="xl">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                    <th>Table heading</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                    <td>Table cell</td>
                </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default WorkOut;