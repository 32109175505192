import React, { useState, useEffect } from 'react';
import {Container, Row, Table, Alert} from 'react-bootstrap';
import * as UserDiet from '../service/UserDietService';
import CSVReader from './CSVReader';

function Administrator() {
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    // const [groups, setGroups] = useState();
    const [getUserDiet, setUserDiet] = useState(null);

    const PUID = sessionStorage.getItem('fireUID');
    
    console.log(getUserDiet);

    useEffect(() =>{
        async function fetchData(){
            try{
                const response = await UserDiet.getUserDiet(PUID);
                if(response != ''){
                    setUserDiet(response);
                }
            } catch (error) {
                setIsLoading(false);
                setIsErrorFree(false)
            }
            setIsLoading(false);
            console.log('USER DIET:    ' + getUserDiet);
        }

        fetchData();
    },[]);

    const renderView = () => {
        return(
            <CSVReader></CSVReader>
        );
    }

    const createMainInformation = () => {
        // if (isErrorFree) {
            return getUserDiet !== null ? renderView() : null;
        // } else {
        //     return (
        //     <Container>
        //         <Alert variant='danger'>
        //             <Alert.Heading>Error! Our apologies</Alert.Heading>
        //             <p>
        //                 Something went wrong, try again later.
        //             </p>
        //         {getUserDiet !== null ? renderView() : null}
        //         </Alert>
        //     </Container>
        //     );
        // }
    };
    return(
        <CSVReader></CSVReader>
        // <>
        // {createMainInformation()}
        // </>
    )

};

export default Administrator;