import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import './nav-bar.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactSection.scss';
// import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';

class ContactSection extends Component {

    //<!-- Contact Section -->
    render() {
    return <section class="contact-section bg-black">
            <div class="container">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-6 button_AreUPhotographer">
                        <button onclick="Photographer()" type="button" class="btn btn-sm btn-block text-purple">¿Eres nutriólogo?</button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-3 mb-md-0">
                        <div class="card py-4 h-100">
                            <div class="card-body text-center">
                                <link href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" rel="stylesheet"/>
                                <i class="fas fa-map-marked-alt text-purple mb-2"></i>
                                <h4 class="text-uppercase h4Text">Direccion</h4>
                                <hr class="my-4"></hr>
                                <div class="small text-black-50">Calle San Javier, Guadalajara JAL</div>
                            </div>
                        </div>
                    </div>

                <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <i class="fas fa-envelope text-purple mb-2"></i>
                            <h4 class="text-uppercase h4Text">Email</h4>
                            <hr class="my-4"></hr>
                            <div class="small text-black-50">
                                <a href="mailto:rebeaguirre@hotmail.com">rebeaguirre@hotmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <i class="fas fa-mobile-alt text-purple mb-2"></i>
                            <h4 class="text-uppercase h4Text">Telefono</h4>
                            <hr class="my-4"></hr>
                            <div class="small text-black-50">33-14-53-6727</div>
                        </div>
                    </div>
                </div>
                </div>

                <div class="social d-flex justify-content-center">
                    {/* <!--#ce31b2--> */}
                <a href="https://www.instagram.com/nutriologarebeca/" class="mx-2">
                    <i class="fab fa-instagram text-purple"></i>
                </a>
                <a href="https://www.facebook.com/rebecaAM94" class="mx-2">
                    <i class="fab fa-facebook-f text-purple"></i>
                    {/* <!-- other facebook "\f39e" --> */}
                </a>
                {/* <!-- <a href="#" class="mx-2">
                    <i class="fab fa-github"></i>
                </a> --> */}
                </div>
            </div>
            </section>
    }
}

export default withRouter(ContactSection);