import React, { useContext } from 'react';
import {Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/AuthContext';
// import tokenUtil from '../Utils/CheckToken';
// import { getHostName } from '../Utils/CheckHostName';


const PrivateRoute = ({ component: Component, displayComponent, ...rest }) => {

  const {currentUser} = useContext(AuthContext);
  // let redirectLogin = getHostName();
  // let auth = tokenUtil.validToken();

  // if (!auth) {
  //   return window.location = redirectLogin;
  // }
  
  return (
    <Route
      {...rest}
      render={props =>
        (
          displayComponent ?
          <Component {...props} />
          :
          <Redirect to='/' />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
    component: PropTypes.func
}

export default PrivateRoute;