import axios from '../api/AxiosInstance'


export const getAllMeasurments = async () => {
    const url = '/customersMeasurments';

    return await axios
        .get(url)
        .then((response) => {
            if (response.data !== "") {
                console.log(response);
            //   if (!allowedLocationCodes.includes(response.data.locationCode)) {
            //     response.data = "";
            //   }
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export const getUserMeasurments = async (userid) => {
    const url = '/measurments/' + userid;

    return await axios
        .get(url)
        .then((response) => {
            if (response.data !== "") {
                console.log(response);
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

// export const createNewUser = async (body) => {
//     const url = '/customer';

//     return await axios
//         .post(url,body)
//         .then((response) => {
//             return response.data;
//         })
//         .catch((error) => {
//             throw error;
//         });
// };