import React, { ReactFileReader, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import * as userDietService from '../service/UserDietService';
import * as workoutsService from '../service/WorkoutsService';

function CSVReader() {
  // const handleFiles = files => {
  //   var reader = new FileReader();
  //   reader.onload = function(e) {
  //       // Use reader.result
  //       alert(reader.result)
  //   }
  //   reader.readAsText(files[0]);
  // }

  const [file, setFile] = useState();
  const [excelFile, setExcelFile] = useState();

  const PUID = sessionStorage.getItem('fireUID');

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleOnChangeExcel = (e) => {
    setExcelFile(e.target.files[0]);
  };

  //Submit Diet File
  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        console.log(csvOutput);
        //MANDA CSV Y LO GUARDA EN LA TABLA DIET EN DB
        userDietService.createNewDiet(csvOutput);
      };
      fileReader.readAsText(file);
      // console.log(fileReader.readAsText(file));
    }
  };

  //Submit Workout File
  const handleOnSubmitFile = (e) => {
    e.preventDefault();
    if (excelFile) {
      fileReader.onload = function (event) {
        const excelOutput = event.target.result;
        console.log(excelOutput);
        //MANDA CSV O XLSX FILE Y LO GUARDA EN DB
        workoutsService.createNewWorkouts(excelOutput);//, PUID);
      };
      fileReader.readAsText(excelFile);
      // console.log(fileReader.readAsText(file));
    }
  };

  //DOWNLOAD FILE BUTTON
  const handleOnDownloadFile = (e) => {
    e.preventDefault();
    userDietService.downloadDietExcel(PUID);
  };

  return (
      // <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
      //   <button className='btn'>Upload</button>
      // </ReactFileReader>
      <div style={{ textAlign: "center" }}>
        <Row>
        <h1>Subir dieta CSV</h1>
          <form>
            <input
                type={"file"}
                id={"csvFileInput"}
                accept={[".xls",".xlsx",".csv",".xlsb"]}
                onChange={handleOnChange}
            />
            <button
                onClick={(e) => {
                    handleOnSubmit(e);
                }}
            >
                IMPORT CSV
            </button>
          </form>
          </Row>
          <Row>
            <p>

            </p>
          </Row>
          <Row>
          <div style={{ textAlign: "center" }}>
            <h1>Subir Ejercicio CSV</h1>
              <form>
                <input
                    type={"file"}
                    id={"excelFileInput"}
                    accept={[".xls",".xlsx",".csv",".xlsb"]}
                    onChange={handleOnChangeExcel}
                />
                <button
                    onClick={(e) => {
                      handleOnSubmitFile(e);
                    }}
                >
                    IMPORT CSV
                </button>
              </form>
          </div>
          </Row>
          <Button onClick={(e)=> {
            handleOnDownloadFile(e);
          }}>Dawnload</Button>
      </div>
      
  );
}


export default CSVReader;