import axios from '../api/AxiosInstance';
import * as XLSX from 'xlsx';
import { faSheetPlastic } from '@fortawesome/free-solid-svg-icons';


export const getAllUsersDetails = async () => {
    const url = '/customers';

    return await axios
        .get(url)
        .then((response) => {
            if (response.data !== "") {
                console.log(response);
            //   if (!allowedLocationCodes.includes(response.data.locationCode)) {
            //     response.data = "";
            //   }
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export const getUserDiet = async (uid) => {
    const url = '/diet/' + uid;

    return await axios
        .get(url)
        .then((response) => {
            if (response.data !== "") {
                console.log(response);
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export const createNewUser = async (body) => {
    const url = '/customer';

    return await axios
        .post(url,body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const createNewDiet = async (fileName) => {
    const url = '/diet/loadDiet';
    const data = new FormData();
    data.append('fileName', new File([fileName], { type: 'text/csv' }));

    // , {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }

    // }
    return await axios
        .post(url, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
        
            })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

//Experiment on feature submit complete Excel file 
export const createNewDietExcel = async (fileName, UID) => {
    const url = '/diet/loadExcelFile';
    const data = new FormData();

    let ws = XLSX.utils.sheet_to_csv(fileName);
    // let wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, data, "sheet");
    // let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    // let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser

    //data.append('fileName', new Blob([fileName], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    data.append('fileName', new Blob([ws], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    data.append('UID', UID);

    // , {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }

    // }
    return await axios
        .post(url, data, {
                headers: {
                    //'Content-Type': 'multipart/form-data'
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
        
            })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var binaryLen = base64.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }
 function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
 };

 const downloadxls = (data)=>{
    
    let ws = XLSX.utils.sheet_to_csv()
    //let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, "sheet");
    // let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    // let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
    XLSX.writeFile(wb, `myfilename.xls`);
 }

export const downloadDietExcel = async (UID) => {
    const url = '/diet/downloadExcelFile/' + UID;

    return await axios
        .get(url, {
            method:'GET',
            // responseType: 'blob', // important  
            // headers: { "Content-Type": "blob" }          
        })
        .then((response) => {
            //application/vnd.ms-excel
            //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            console.log(response.data)
            downloadxls(response.data)
            //var bytes = new Uint8Array(response.data); // pass your byte response to this constructor
            //console.log(bytes);
            //var sampleArr = base64ToArrayBuffer(response.data);
            //saveByteArray("Sample Report", sampleArr);
            // var blob = new Blob([bytes], {type: "application/vnd.ms-excel"});
            
            /*var blob = new File([response.data], {type: "application/vnd.ms-excel"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', `${Date.now()}.xls`);*/

            // link.download = "Sample Report";
            
            //link.click();

            //const url = window.URL.createObjectURL(new Blob([response.data],'File.xls'));
            /*const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();*/
            //----------------------------
            // var blob = new Blob([arrayBuffer], { type: type });
            // var url = URL.createObjectURL(blob);
            // window.open(url);
        })
        .catch((error) => {
            throw error;
        });

}
// axios.post(url, {
//     method: 'GET',
//     responseType: 'blob', // important
// }).then((response) => {
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', `${Date.now()}.xlsx`);
//     document.body.appendChild(link);
//     link.click();
// });