import React, { useState } from 'react';
// import { Link, withRouter } from 'react-router-dom';
import './NavBar.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav,
    Container,
    NavDropdown,
    Navbar,
    Button} from 'react-bootstrap';
import fire from '../config/Firebase';

// class NavBar extends Component {
function NavBar(props) {
  


    // emptyItem = {
    //     max_price: '',
    //     min_price: ''
    // };

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         item: this.emptyItem
    //     };
    //     this.handleChange = this.handleChange.bind(this);
    //     this.handleSubmit = this.handleSubmit.bind(this);
    // }

    // async componentDidMount() {
    //     if (this.props.match.params.id !== 'new') {
    //         const group = await (await fetch(`/priceLimits/${this.props.match.params.id}`)).json();
    //         this.setState({ item: group });
    //     }
    // }

    // handleChange(event) {
    //     const target = event.target;
    //     const value = target.value;
    //     const name = target.name;
    //     let item = { ...this.state.item };
    //     item[name] = value;
    //     this.setState({ item });
    // }

    // async handleSubmit(event) {
    //     event.preventDefault();
    //     const { item } = this.state;

    //     await fetch('/priceLimits', {
    //         method: (item.id) ? 'PUT' : 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(item),
    //     });
    //     this.props.history.push('/groups');
    // }
    const [isOpen, setIsOpen] = useState(false);
    const [isEnableSOButton, setIsEnableSOButton] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    function enableSignOutButton(){
      if(props.currentUser){
        console.log(props.currentUser);
        return (<div>
                  <Nav.Link href="/">
                    <Button className='navTitle' onClick={() => {fire.auth().signOut();}} variant="outline-light">LOGOUT</Button>{' '}
                  </Nav.Link>
                </div>)
      } else {
        return(<Nav>
            <Nav.Link href="/SignUp">
              <Button className='navTitle' variant="light">REGISTER</Button>{' '}
            </Nav.Link>
            <Nav.Link href="/SignIn">
              <Button className='navTitle' variant="outline-light">LOGIN</Button>{' '}
            </Nav.Link>
            </Nav>)
      }
    }
    // render() {
        // const { item } = this.state;
        // const title = <h2>{item.id ? 'Edit Group' : 'Add Group'}</h2>;

        //<!--Navigation-->
        // return <Nav className='navbar navbar-expand-lg navbar-light navbar-color'>
        //     <a className="navbar-brand" href="#">Ro Nutirólogos</a>
        //     <Button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        //         <span className="navbar-toggler-icon"></span>
        //     </Button>
        return (
          <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
              <Navbar.Brand  className='navBrand' href="/">Rebe Aguirre</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  {/* <Nav.Link href="#features">Features</Nav.Link>
                  <Nav.Link href="#pricing">Pricing</Nav.Link>
                  <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
                <Nav>
                  {enableSignOutButton()}                  
                  {/* <NavDropdown title="Profile" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/SignIn">Sign In</NavDropdown.Item>
                    <NavDropdown.Item href="/SignUp">
                      Sign Up
                    </NavDropdown.Item>
                    {enableSignOutButton()}                    
                  </NavDropdown> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        );
    //     return <div>
    //             <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    //               {/* color="light" light expand="md" */}
    //     <Navbar.Brand href="/">reactstrap</Navbar.Brand>
    //     <NavbarToggler onClick={toggle} />
    //     <Collapse isOpen={isOpen} navbar>
    //       <Nav className="me-auto" navbar>
    //         <NavItem>
    //           <NavLink href="/components/">Components</NavLink>
    //         </NavItem>
    //         <NavItem>
    //           <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
    //         </NavItem>        
    //         {/* <NavItem>
    //             <NavLink href="/SignUp">Sign Up</NavLink>
    //         </NavItem> */}
    //       </Nav>
    //       <Nav>
    //         <UncontrolledDropdown nav inNavbar>
    //           <DropdownToggle nav caret>
    //             Profile
    //           </DropdownToggle>
    //           <DropdownMenu left>
    //             <DropdownItem href="/SignIn">
    //               Sign In    
    //             </DropdownItem>
    //             <DropdownItem href='/SignUp'>
    //               Sign Up     
    //             </DropdownItem>
    //             <DropdownItem divider />
    //             <DropdownItem onClick={() => {fire.auth().signOut();}} href='/'>
    //               Sign Out
    //               {/* return <Redirect to="/" /> */}
    //             </DropdownItem>
    //           </DropdownMenu>
    //         </UncontrolledDropdown>  
    //       </Nav>
    //     </Collapse>
    //   </Navbar>
    // </div>
        {/* </Nav> */}
        
        
        
        {/* <div>
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="name">Name</Label>
                        <Input type="text" name="name" id="name" value={item.name || ''}
                            onChange={this.handleChange} autoComplete="name" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="address">Address</Label>
                        <Input type="text" name="address" id="address" value={item.address || ''}
                            onChange={this.handleChange} autoComplete="address-level1" />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/groups">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div> */}
    // }
}

// export default withRouter(NavBar);
export default NavBar;