import { useState, ReactFileReader, useEffect} from 'react';
import {Tabs, Tab, Col} from 'react-bootstrap';
import './ControlledTabs.scss';
import * as UserInfo from '../service/UserDetailsService';
import Diet from './Diet';
import ProgressProfile from './ProgressProfile'
import WorkOut from './WorkOut';
import Profile from './Profile';
import Administrator from './Administrator';
import {Nav, NavItem, NavLink, Row} from 'reactstrap'
function ControlledTabs() {
    const [key, setKey] = useState('profile');
    const [isLoading, setIsLoading] = useState(true);
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [userProfileTab, setProfileTab] = useState(null);

    const PUID = sessionStorage.getItem('fireUID'); 

    console.log(userDetails)
    // console.log(userDetails.props.userDetail.role);

    useEffect(() =>{
      // setIsLoading(true);
      //const fetchData = async() =>{};
      async function fetchData(){
        try{
          const response = await UserInfo.getUserDetails(PUID);//PriceInfo.getPriceLimits();
          if(response != ''){
            setUserDetails(response);
            setProfileTab(<Profile userDetail={response}></Profile>)
            // setUserDetails(response);
          }
        } catch (error) {
          setIsLoading(false);
          setIsErrorFree(false)
        }
        //const body = await response.json();
        setIsLoading(false);
        // console.log('USER DETAILS:    ' + userDetails);
      }
      fetchData();
    },[]);

    const uploadFilesTab = () => {
      if(userDetails != null){
        if(userDetails.role == 1){
          return(
            <Tab eventKey="uploadDiet" title="Upload Diet">
                <h1>Subir datos</h1>
                {/* <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
                  <button className='btn'>Upload</button>
                </ReactFileReader> */}
                <Administrator></Administrator>
            </Tab>
          )
        }
        // }else{
        //   return '';
        // }
      }
    }
    
    return (
        // <div>
        //     <Nav
        //         justified
        //         tabs
        //     >
        //         <NavItem>
        //         <NavLink
        //             active
        //             href="#"
        //         >
        //             Link
        //         </NavLink>
        //         </NavItem>
        //         <NavItem>
        //         <NavLink href="#">
        //             Another Link
        //         </NavLink>
        //         </NavItem>
        //         <NavItem>
        //         <NavLink
        //             disabled
        //             href="#"
        //         >
        //             Disabled Link
        //         </NavLink>
        //         </NavItem>
        //     </Nav>
        //     </div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab className='tabText' eventKey="profile" title="Profile">
            <h1 className='tabTextTitle'>Perfil</h1>
            {userProfileTab}
            {/* <Profile userDetail={userDetails}></Profile> */}
        </Tab>
        <Tab className='tabText' eventKey="home" title="Home">
          <h1 className='tabTextTitle'>{process.env.REACT_APP_TITLE_DIET_TAB}</h1>
          <Diet></Diet>
        </Tab>
        <Tab className='tabText' eventKey="progressprofile" title="Progress Profile">
            <h1 className='tabTextTitle'>Mi Progreso</h1>
            <ProgressProfile></ProgressProfile>
        </Tab>
        <Tab className='tabText' eventKey="workout" title="Work Out">
            <h1 className='tabTextTitle'>Rutina De Ejercicios</h1>
            <WorkOut></WorkOut>
        </Tab>
        {uploadFilesTab()}
        <Tab className='tabText' eventKey="contact" title="Contact" disabled>
          
        </Tab>
      </Tabs>
    );
};
export default ControlledTabs;
//   render(<ControlledTabs />);