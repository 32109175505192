import React, {useContext, useCallback} from 'react';
import accountCircle from '../img/icon/baseline_account_circle_black_48dp.png'
import {Form, Row, Col, Button, Container, Figure} from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import './NavBar.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
// import history from '../helpers/history';
import fire from '../config/Firebase';
import { AuthContext } from '../context/AuthContext';
// import { Button, Form, Input, Nav, List,
//     Collapse,
//     Navbar,
//     NavbarToggler,
//     NavbarBrand,
//     NavItem,
//     NavLink,
//     UncontrolledDropdown,
//     DropdownToggle,
//     DropdownMenu,
//     DropdownItem,
//     NavbarText} from 'reactstrap';

// class NavBar extends Component {
function LogInForm() {
    // const [isOpen, setIsOpen] = useState(false);
    let history = useHistory();
    // const toggle = () => setIsOpen(!isOpen);
    const handleLoginForm = useCallback(async event => {
        event.preventDefault();
        const { email, password } = event.target.elements;
        console.log(email.value, password.value);//email.value,
        //console.log(name_field_signup.value, lastName_field_signup.value);//email.value,
        try{
            await fire
            .auth()
            .signInWithEmailAndPassword(email.value, password.value);
            history.push("/Dashboard");
            // history.replace("/"); 
        } catch(error) {
            alert(error);
        }
    }, [history]);

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to="/" />;
    }
    //----------------------------- YA FUNCIONA ----------------------------------------------------------------------
    // return (
    // <div>
    //     <div id="user-emailFacebook"></div>
    //     <div id="profile-pic"></div>
    //     <div id="display-name"></div>

    //     <form id="signup-form" class="form-signin" role="form"></form>
    //     <div class="login-form">
    //         <form id="login-form" class="form-signin-heading" onSubmit={handleLoginForm} role="form">
    //             <div class="avatar">
    //                 <img src="img/user(1).png" alt="Avatar"></img>
    //             </div>
    //             <h2 class="text-center">Member Login</h2>
    //             <div class="form-group">
    //                 <input type="email" class="form-control" name="email" placeholder="Email address" required="required" id="email_field_login"></input>
    //             </div>
    //             <div class="form-group">
    //                 <input type="password" class="form-control" name="password" placeholder="Password" required="required" id="password_field_login"></input>
    //                 <button  type='submit' class="btn btn-primary btn-lg btn-block" >Sign in</button>
    //             </div>
    //             <div class="form-group">
    //             </div>
    //         </form>
    //         <p class="text-center small">Don't have an account? <a href="/signUp">Sign up here!</a></p>
    //         {/* <!--onclick="signUp()"--> */}

    //         <button class="loginBtn loginBtn--facebook" onclick="signinFacebook()" id="sign-inFacebook">
    //             Login with Facebook
    //         </button>

    //     </div>

    // </div>);
    //-------------------------------------------------------------------------------------------------------------------------

    return(
        <Container>
            <h1></h1>
            <h2 class="text-center">Member Login</h2>
            <Row className="justify-content-md-center">
                <Col xs lg="2">
                <Figure>
                    <Figure.Image
                        width={171}
                        height={180}
                        alt="171x180"
                        // src="holder.js/171x180"
                        src={accountCircle}
                    />
                </Figure>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs lg="4">
                    <Form onSubmit={handleLoginForm}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={2}>
                            Email
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control type="email" placeholder="Email" id="email"/>
                        </Col>
                        </Form.Group>
                
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2}>
                            Password
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control type="password" placeholder="Password" id="password"/>
                        </Col>
                        </Form.Group>
                        <fieldset>
                        
                        </fieldset>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Form.Check label="Remember me" />
                        </Col>
                        </Form.Group>
                
                        <Form.Group as={Row} className="mb-3">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit">Sign in</Button>
                        </Col>
                        </Form.Group>
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button class="btn-lg btn-block btn-social btn-facebook" onclick="signinFacebook()" id="sign-inFacebook">
                                Login with Facebook
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
            {/* <Button class="loginBtn loginBtn--facebook" onclick="signinFacebook()" id="sign-inFacebook">
                 Login with Facebook
             </Button> */}
            <p class="text-center small">Don't have an account? <a href="/signUp">Sign up here!</a></p>
             {/* <!--onclick="signUp()"--> */}
        </Container>
    );
       
};

export default LogInForm;